<template>
  <v-dialog v-model="dialog" persistent max-width="650px">
    <Create :createDialog="categoryCreateDialog" @close="categoryCreateDialog = false" @submit="getCategory"></Create>
    <DepartmentCreate :createDialog="departmentCreateDialog" @close="departmentCreateDialog = false" @submit="getDepartment"></DepartmentCreate>
    <BrandCreate :createDialog="brandCreateDialog" @close="brandCreateDialog = false" @submit="getBrand"></BrandCreate>
    <v-card grid-acklist-md>
      <v-toolbar dense class="mb-3" flat color="grey lighten-3">
        <v-toolbar-title class="subtitle-1">Edit Product</v-toolbar-title>
        <v-spacer> </v-spacer>
        <v-checkbox label="Product Active" hide-details outlined dense class="mr-2"
          v-model="Product.active"></v-checkbox>
        <v-btn small icon @click.stop.prevent="close()">
          <v-icon small>close</v-icon>
        </v-btn>
      </v-toolbar>
      <!-- <ModelTitle title="Edit Product" @close="close()" /> -->

      <v-container v-if="Product">
        <v-row>
          <v-col cols="12" sm="12">
            <v-row>
              <v-col cols="12" sm="12" md="3">
                <ImageView :name="searchName" field="product" :initUrl="Product.imageUrl" @submit="imageChange" />
              </v-col>
              <v-col sm="12" md="9">
                <v-row>
                  <v-col cols="12" sm="12">
                    <v-text-field v-model="Product.name" :error-messages="nameErrors"
                      :hide-details="nameErrors.length === 0" outlined autofocus @change="changeName"
                      @focus="$event.target.select()" dense label="Name" ref="productName"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" sm="12">
                    <v-select :items="Category" clearable :hide-details="selectCategoryError.length === 0"
                      :error-messages="selectCategoryError" v-model="Product.CategoryId" item-text="name" outlined dense
                      item-value="id" label="Select Category"><template v-slot:append-item>
                        <v-divider class="mb-2"></v-divider>
                        <v-list-item @click="categoryCreateDialog = true">
                          <v-list-item-content>
                            <v-list-item-title>Create New Category</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template></v-select>
                  </v-col>
                  <v-col cols="12" md="6" sm="12">
                    <v-select :items="Department" clearable hide-details v-model="Product.DepartmentId" item-text="name" outlined dense
                      item-value="id" label="Select Department"><template v-slot:append-item>
                        <v-divider class="mb-2"></v-divider>
                        <v-list-item @click="departmentCreateDialog = true">
                          <v-list-item-content>
                            <v-list-item-title>Create New Department</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template></v-select>
                  </v-col>
                  <v-col cols="12" md="6" sm="12">
                    <v-select :items="Brand" clearable hide-details v-model="Product.BrandId" item-text="name" outlined dense
                      item-value="id" label="Select Brand"><template v-slot:append-item>
                        <v-divider class="mb-2"></v-divider>
                        <v-list-item @click="brandCreateDialog = true">
                          <v-list-item-content>
                            <v-list-item-title>Create New Brand</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template></v-select>
                  </v-col>
                  <v-col cols="12" md="6" sm="12" v-if="currentBusiness.metadata && currentBusiness.metadata.parentChile && !Product.isParent">
                      <v-select v-if="Products" :items="Products" clearable v-model="Product.ChildProductId"
                        hide-no-data item-text="name" outlined dense item-value="id"
                        label="Select Parent Product"><template v-slot:append-item>
                        </template>
                      </v-select>
                    </v-col>
                  

                </v-row>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" sm="12" md="4" v-if="!Product.isParent">
            <v-text-field v-model="Product.upc" @focus="$event.target.select()" outlined
              @click:append="generateRandomUPC" append-icon="mdi-barcode-scan" hide-details dense
              label="UPC Code"></v-text-field>
          </v-col>

          <v-col cols="12" sm="12" md="4" v-if="!Product.isParent">
            <v-text-field v-model="Product.cost" @focus="$event.target.select()" class="right-input" outlined
              hide-details dense label="Purchase Cost"></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="4" v-if="!Product.isParent">
            <v-text-field v-model="Product.price" class="right-input" label="Price" outlined dense hide-details
              @focus="$event.target.select()"></v-text-field>
          </v-col>

          <v-col cols="12" sm="12" md="4" v-if="Product.trackInventory && !Product.trackSerialNumber">
            <v-text-field v-model="Product.initBalance" class="right-input" hide-details
              :disabled="!checkRightStatus(46)" outlined dense @focus="$event.target.select()"
              label="Opening Balance"></v-text-field>
          </v-col>

          <v-col cols="12" sm="12" md="4" v-if="Product.trackInventory">
            <v-text-field readonly v-model="Product.balance" class="right-input" hide-details
              :disabled="!checkRightStatus(46)" outlined dense @focus="$event.target.select()"
              label="Current Stock"></v-text-field>
          </v-col>
        </v-row>
        <div v-if="Product.trackInventory">
          <v-subheader>Reorder Point</v-subheader>
          <v-row>
            <v-col cols="12" sm="12" md="4">
              <v-text-field v-model="Product.min" class="right-input" label="Min" outlined dense hide-details
                @focus="$event.target.select()"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-text-field v-model="Product.max" class="right-input" label="Max" outlined dense hide-details
                @focus="$event.target.select()"></v-text-field>
            </v-col>
          </v-row>
          <div v-if="currentBusiness && currentBusiness.metadata && currentBusiness.metadata.boxCountOnInventory">
            <v-subheader>Unit count in the box</v-subheader>
            <v-row>
              <v-col cols="12" sm="12" md="4">
                <v-text-field type="number" v-model="Product.unitCountInBox" class="right-input" label="Unit Count"
                  outlined dense hide-details @focus="$event.target.select()"></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="4">
                <!-- <v-text-field type="number" readonly v-model="Product.boxPrice" class="right-input" label="Box Price"
                  outlined dense hide-details @focus="$event.target.select()"></v-text-field> -->
                <v-text-field type="number" readonly v-model="Product.unitPrice" class="right-input" label="Unit Price"
                  outlined dense hide-details @focus="$event.target.select()"></v-text-field>
              </v-col>
            </v-row>
          </div>

        </div>
        <v-row>
          <v-col cols="12" sm="12" md="4" v-if="!Product.isParent">
            <v-checkbox label="Track Inventory" hide-details outlined dense :disabled="trackInventory"
              v-model="Product.trackInventory"></v-checkbox>
          </v-col>
          <v-col cols="12" sm="12" md="4"
            v-if="this.currentBusiness.metadata && this.currentBusiness.metadata.serializeProduct">
            <v-checkbox label="Track Serial Number" hide-details outlined dense v-model="Product.trackSerialNumber">
            </v-checkbox>
          </v-col>
          <v-col cols="12" sm="12" md="4" v-if="!Product.isParent">
            <v-checkbox label="Remote Ordering" hide-details outlined dense v-model="Product.remoteOrder"></v-checkbox>
          </v-col>

          <v-col cols="12" sm="12" md="4" >
            <v-checkbox label="Mark Special" hide-details outlined dense v-model="Product.special"></v-checkbox>
          </v-col>


          <v-col cols="12" sm="12" md="4" v-if="currentBusiness.metadata && currentBusiness.metadata.dynamicPrice">
            <v-checkbox label="Standard Pricing" hide-details outlined dense
              v-model="Product.metadata.standardPrice"></v-checkbox>
          </v-col>

        </v-row>
        <v-row>
          <v-col>
            <v-checkbox class="mt-0" label="Parent Product" hide-details outlined dense
              :disabled="Product.trackSerialNumber" v-model="Product.isParent"></v-checkbox>
            <div class="caption">As a Parent Product, This Item Will No Longer Be Available for Sale, But You Can Add
              and Manage
              Sub-Products Under It</div>
          </v-col>

          <v-col v-if="!Product.isParent">
            <v-checkbox class="mt-0" label="Apply Weight" hide-details outlined dense
              v-model="Product.metadata.weightApply">
            </v-checkbox>
            <div class="caption">Weight will ask on this product sale and multiply with sale price</div>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="12">
            <v-textarea counter="counter" hide-details outlined dense auto-grow v-model="Product.note"
              :label="$t('labels.description')"></v-textarea>
          </v-col>
        </v-row>
      </v-container>
      <!-- <v-card-text v-if="editProduct">
        <AttachmentMiniList :id="editProduct.id" type="PRODUCT" />
      </v-card-text> -->
      <v-divider></v-divider>
      <v-card-actions>
        <!-- <MoreImage
          ref="addImageUrl"
          :imageResult="imageResult"
          @submit="imageChange"
        /> -->
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text="text" @click="close()">Close</v-btn>
        <v-btn color="pink lighten-1" :loading="loading" dark @click="update()">Update</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import productService from "../service.js";
import ImageView from "@/components/Image/ImageView";
import categoryService from "@/modules/Product/Category/service";
import departmentService from "@/modules/Product/Department/service";
import brandService from "@/modules/Product/Brand/service";
import GlobalFunction from "@/mixins/GlobalFunction";
import _ from "lodash";

const { validationMixin } = require("vuelidate");
const { required, minLength, minValue } = require("vuelidate/lib/validators");
const Create = () => import("@/modules/Product/Category/components/Create");
const DepartmentCreate = () => import("@/modules/Product/Department/components/Create");
const BrandCreate = () => import("@/modules/Product/Brand/components/Create");

export default {
  props: {
    editDialog: {
      default: false,
    },
    id: {
      default: 0,
    },
  },
  name: "edit-product",
  data() {
    return {
      selectedProduct: null,
      productSearchLoading: false,
      Products: [],
      searchName: null,
      categoryCreateDialog: false,
      departmentCreateDialog: false,
      brandCreateDialog: false,
      Department: [],
      Brand: [],
      Category: [],
      imageResult: null,
      selectedCategory: null,
      dialog: false,
      loading: false,
      trackInventory: false,
      metadata: {
        weightApply: false,
        standardPrice: false,
      },
      Product: {
        upc: null,
        isParent: false,
        name: null,
        isParent: false,
        note: null,
        price: null,
        cost: 0,
        boxPrice: null,
        unitCountInBox: null,
        imageUrl: null,
        trackSerialNumber: false,
        balance: 0,
        trackInventory: false,
        min: null,
        max: null,
        active: true,
        metadata: {
          weightApply: false,
          standardPrice: false,
        }
      },
    };
  },
  components: {
    Create,
    ImageView,
    BrandCreate,
    DepartmentCreate,
  },
  mixins: [validationMixin, GlobalFunction],
  validations: {
    Product: {
      name: {
        required,
        minLength: minLength(5),
      },
      CategoryId: {
        required,
        minValue: minValue(1),
      },
    },
  },
  computed: {
    ...mapGetters("global", [
      "comboBox",
      "comboBoxByID",
      "checkRightStatus",
      "currentUser",
      "currentBusiness",
    ]),
    nameErrors() {
      const errors = [];
      if (!this.$v.Product.name.$dirty) return errors;
      if (!this.$v.Product.name.required)
        errors.push(this.$t("validations.fieldIsRequired"));
      if (!this.$v.Product.name.minLength)
        errors.push("Field must be at least 5 characters long");
      return errors;
    },
    selectCategoryError() {
      const errors = [];
      if (!this.$v.Product.CategoryId.$dirty) return errors;
      if (!this.$v.Product.CategoryId.required)
        errors.push(this.$t("validations.fieldIsRequired"));
      if (!this.$v.Product.CategoryId.minValue)
        errors.push("Pleaes select category");

      return errors;
    },
  },
  watch: {
    'Product.isParent': function (val) {
      if (!val) {
        this.selectedProduct = null
      }
    },
    'Product.unitCountInBox': function () {
      this.updateboxPrice();
    },
    'Product.price': function () {
      this.updateboxPrice();
    },
    Product: {
      handler(val) {
        if (val.trackSerialNumber) {
          this.Product.trackInventory = true;
          this.trackInventory = true;
        } else {
          this.trackInventory = false;
        }
      },
      deep: true,
    },
    editDialog(val) {
      this.dialog = val;
      if (val) {
        this.getCategory();
        this.getDepartment();
        this.getBrand();
        this.loadProduct();
        this.getProduct();
        // console.log('checkRightStatus(47)', this.checkRightStatus(47))
      }
    },
  },
  methods: {
    // openImageBox() {
    //   // console.log("ref", this.$refs);
    //   this.$refs.addImageUrl.$refs.addImageUrl.$el.click();
    // },
    generateRandomUPC() {
      let upc = 'C'; // Start with 'C'
      for (let i = 1; i < 12; i++) { // Start the loop from 1 because 'C' is already added
        upc += Math.floor(Math.random() * 10); // Generate a random digit (0-9)
      }
      this.Product.upc = upc;
    },
    getProduct() {
      this.loading = true;

      return productService.getAll({ isParent: true }).then((result) => {
        console.log('get prodcut', result)
        this.Products = result.data;
        // if (this.currentBusiness.metadata && this.currentBusiness.metadata.dynamicPrice) {
        //   this.Products.map(row => {
        //     row.dynamicPrice = this.getDynamicPrice(row, this.currentBusiness.metadata.dynamicPriceList)
        //   })
        // }
        this.loading = false;
      });
    },
    updateboxPrice() {
      if (
        this.Product.trackInventory &&
        this.Product.unitCountInBox > 0 &&
        this.Product.price > 0
      ) {
        this.Product.boxPrice = (this.Product.price * this.Product.unitCountInBox).toFixed(2);
        this.Product.boxPrice = parseFloat(this.Product.boxPrice);
      }
    },
    loadProduct() {
      return productService.getById(this.id).then((result) => {
        // console.log("produ", result);

        this.Product = result.data;

        this.Product.metadata = {
          ...this.metadata,
          ...this.Product.metadata,
        }

        // console.log('test prodcut', this.Product)
        // if (this.Product) {
        //   console.log("produ", this.Product.metadata, !this.Product.metadata.weightApply);
        //   this.Product.metadata = {
        //     weightApply: false
        //   }
        // }
      });
    },
    imageChange(val) {
      // // console.log('currentBusiness', val)
      this.Product.imageUrl = val;
      // if (val) {
      //   this.Product.imageUrl = val;
      // }
    },
    changeName() {
      if (this.Product && this.Product.name) {
        this.Product.name = this.properCase(this.Product.name);
        this.searchName = this.Product.name;
      }
    },
    updateAvgCost() {
      productService.getAvgCost(this.Product.id)
    },
    
    close() {
      this.updateAvgCost()
      this.empty();
      this.$emit("close");
    },
    getCategory() {
      this.loading = true;
      return categoryService.getAll().then((response) => {
        this.loading = false;
        this.Category = response.data;
        return response;
      });
    },
    getDepartment() {
      this.loading = true;
      return departmentService.getAll().then((response) => {
        this.loading = false;
        this.Department = response.data;
        return response;
      });
    },
    getBrand() {
      this.loading = true;
      return brandService.getAll().then((response) => {
        this.loading = false;
        this.Brand = response.data;
        return response;
      });
    },
    empty() {
      this.Product = {
        isParent: false,
        name: null,
        isParent: false,
        note: null,
        price: null,
        CategoryId: null,
        imageUrl: null,
        cost: 0,
        min: null,
        max: null,
        boxPrice: null,
        unitCountInBox: null,
        trackSerialNumber: false,
        balance: 0,
        trackInventory: false,
        active: true,
        image: {},
        metadata: {
          weightApply: false
        }
      };
      this.imageUrl = null;
      this.imageResult = null;
      this.$v.$reset();
    },
    update() {
      this.updateAvgCost()
      if (!this.Product.min && this.Product.max) {
        // console.log("in here");
        this.Product.min = parseInt(this.Product.max / 2);
      }

      if (!this.Product.max && this.Product.min) {
        // console.log("in here");
        this.Product.max = parseInt(this.Product.min * 2);
      }

      if (this.Product.max < this.Product.min) {
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          .fire({
            icon: "error",
            title: "Max balance can't more then min balance",
          });
      }
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          .fire({
            icon: "error",
            title: this.$t("toastMessage.text.invalidFields"),
          });
      } else {
        this.loading = true;

        if (this.Product.isParent) {
          this.Product.trackInventory = false
          this.Product.trackSerialNumber = false
          this.Product.ChildProductId = null
        }

        return productService
          .update(this.Product.id, this.Product)
          .then((result) => {
            if (result.status) {
              this.$swal
                .mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                })
                .fire({
                  icon: "success",
                  title: "Product is updated",
                });
              this.empty();
              this.loading = false;
              this.$emit("submit");
            }
          })
          .catch((err) => {
            this.loading = false;
            // console.log("err", err);
            this.$swal
              .mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              })
              .fire({
                icon: "error",
                title: err.data.message,
              });
          });
      }
    },
  },
};
</script>

<style scoped></style>
